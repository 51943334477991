<!-- 安全中心 -->
<template>
  <div class="safecenter">
    <div class="top">
      <el-row class='head'>
        <el-col :span="12">
          <logotitle title='安全中心'></logotitle>
        </el-col>
        <el-col :span="12">
        </el-col>
      </el-row>
    </div>
    <div class="safecnt">
      <div class="title">
        <div class="img">
          <img :src="this.$store.state.user.WxDisplayUrl">
        </div>
        <div class="info">
          <h6>你好，{{this.$store.state.user.ContactName}}</h6>
          <p>建议您启动全部安全设置，以保障账户及资金安全</p>
        </div>
        <svg class="icon"><use xlink:href="#icondun"></use></svg>
      </div>
      <div class="cnt">
        <span  class="icon"><svg><use xlink:href="#icondenglumima"></use></svg></span>
        <div class="info">
          <h6>登录密码</h6>
          <p>互联网账号存在被盗风险，建议您定期更改密码以保护账号安全</p>
        </div>
        <button class="menu" @click='changeLogs'>修改</button>
      </div>
      <div class="cnt cnt1">
        <span  class="icon"><svg><use xlink:href="#iconbangdingshouji"></use></svg></span>
        <div class="info">
          <h6>绑定手机</h6>
          <p>绑定手机号可用于快速找回登录密码，接收账户订单变动提醒等</p>
        </div>
        <button class="menu" @click='resetPhone'>修改</button>
      </div>
      <div class="cnt cnt2">
        <span  class="icon"><svg><use xlink:href="#iconzhifumima"></use></svg></span>
        <div class="info">
          <h6>支付密码</h6>
          <p>启动交易密码后，在使用账户预存余额或奖金时，需要输入交易密码</p>
        </div>
        <button class="menu menuC" v-if='this.$store.state.user.IsSetPaypassWord' @click='changePays'>修改</button>
        <button class="menu" v-if='!this.$store.state.user.IsSetPaypassWord' @click='setPaypas'>立即设置</button>
      </div>
    </div>
  </div>
</template>
<script>
import logotitle from '@/components/logotitle/logotitle.vue';//图片标题组件
export default {
  name: 'safecenter',
  props: {
    msg: String
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
          name: "description",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        },
        {
          name: "Keywords",
          content:
            "微微定wwding.com-纸巾定制上微微定 品质保障"
        }
      ],
    }
  },
  data(){
    return {
      htmltitle:'安全中心-微微定',
    }
  },
  components: {
    logotitle,
  },
  methods:{
    changeLogs(){
      // 修改登录密码
      this._router({then:()=>{
        this.$router.push({path: "/idverify?types=2.html"})
        // this._open({path: "/changepas"})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    changePays(){
      // 修改支付密码
      this._router({then:()=>{
        this.$router.push({path: "/idverify?types=1.html"})
        // this._open({path: "/changepas"})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:this.$route.fullPath+'.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    setPaypas(){
      // 设置支付密码
      this._router({then:()=>{
        this.$router.push('/setsteps.html')
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/setsteps.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    resetPhone(){
      // 修改手机号码
      this._router({then:()=>{
        this.$router.push('/userdata.html')
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/userdata.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  },
  watch:{
  }
}
</script>
<style  lang="scss" scoped>
.safecenter {
  padding-bottom:40px;
  .top {
    width: 100%;background: #fff;padding:10px 0px;
    .head {
      width: 1200px;margin:0 auto;
      .rechargestep {
        float:right;margin-top:5px;
      }
    }
  }
  .safecnt {
    width: 1200px;margin: 0 auto;margin-top:10px;background: #fff;padding-top:30px;padding-bottom:40px;
    .title {
      width: 1140px;margin:0 auto;display: flex;justify-content:flex-start;align-items: center;height: 140px;background: #fdedd9;border-radius: 10px;position: relative;margin-bottom:20px;
      .img {
        margin-left:40px;margin-right: 25px;
        img {
          display: block;width: 80px;height: 80px;border-radius: 50%;
        }
      }
      .info {
        h6 {
          font-size: 18px;
        }
        p {
          font-size:14px;color:#4d4c44;margin-top:5px;
        }
      }
      .icon {
        fill: #fff;width: 140px;height: 140px;position: absolute;right:60px;opacity: 0.4;
      }
    }
    .cnt {
      width: 940px;margin:0 auto;display: flex;justify-content:flex-start;align-items: center;height: 100px;position: relative;
      .icon {
        display: inline-block;width: 44px;height: 44px;background: #6f5ea9;border-radius: 40%;margin-right: 20px;text-align: center;line-height: 44px;
        svg {
          fill: #fff;vertical-align: -7px;width:25px;height: 25px;
        }
      }
      .info {
        h6 {
          font-size: 18px;font-weight: normal;
        }
        p {
          font-size:14px;color:#a5a7a5;margin-top:5px;
        }
      }
      .menu {
        width: 80px;height: 30px;position: absolute;right:0px;border:1px solid #cecece;background: none;border-radius: 5px;line-height: 28px;font-size: 14px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .cnt1 {
      .icon {
        background: #f99970;
      }
    }
    .cnt2 {
      .icon {
        background: #1296db;
      }
      .menu {
        background: #f44e55;border:0px;line-height:30px;color:#fff;
      }
      .menuC {
        background: none;color:#333;border:1px solid #cecece;
      }
    }
  }
}
</style>
